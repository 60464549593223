import React, { useState, useEffect } from "react";
import axios from "axios";
import App from "../../App";
import {data as testData} from "../../assets/test-concerts"


function AppContainer() {
  useEffect(() => {
    
  }, []);


    return <App  />;
}

export default AppContainer;
