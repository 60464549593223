export const data = [
    {
    "id": 0,
    "name": "The North 41",
    "date": "Nov 25, 2022",
    "dayOfWeek": "Friday",
    "venue": " Lincoln Hall",
    "image": "https://d1ct6yhiw4gple.cloudfront.net/wp-content/uploads/2022/05/11-25-2022-the-north-41.png",
    "link": "https://lh-st.com/shows/11-25-2022-the-north-41/",
    "time": "9:00PM",
    "genres": "funk rock",
    "createdAt": "2022-11-26T02:40:53.276Z",
    "updatedAt": "2022-11-26T02:40:53.276Z"
    },
    {
    "id": 1,
    "name": "theMIND",
    "date": "Nov 25, 2022",
    "dayOfWeek": "Friday",
    "venue": " Schubas",
    "image": "https://d1ct6yhiw4gple.cloudfront.net/wp-content/uploads/2022/09/11-25-2022-theMIND.png",
    "link": "https://lh-st.com/shows/11-25-2022-themind/",
    "time": "9:00PM",
    "genres": "",
    "createdAt": "2022-11-26T02:40:53.276Z",
    "updatedAt": "2022-11-26T02:40:53.276Z"
    },
    {
    "id": 2,
    "name": "Edward & Graham",
    "date": "Nov 26, 2022",
    "dayOfWeek": "Saturday",
    "venue": " Lincoln Hall",
    "image": "https://d1ct6yhiw4gple.cloudfront.net/wp-content/uploads/2022/11/11-26-2022-edward-and-graham.png",
    "link": "https://lh-st.com/shows/11-26-2022-edward-and-graham/",
    "time": "8:30PM",
    "genres": "double drumming,indie folk,la indie,stomp and holler",
    "createdAt": "2022-11-26T02:40:53.276Z",
    "updatedAt": "2022-11-26T02:40:53.276Z"
    },
    {
    "id": 3,
    "name": "MODERN COLOR, They Are Gutting A Body Of Water, Soft Blue Shimmer, Mofie",
    "date": "November 25, 2022",
    "dayOfWeek": "Friday",
    "venue": "Beat Kitchen",
    "image": "https://i.ticketweb.com/i/00/11/27/73/69_Listings.jpg?v=6",
    "link": "https://www.beatkitchen.com/event-details/12364945/modern-color-they-are-gutting-a-body-of-water-soft-blue-shimmer-mofie",
    "time": "8:00 pm",
    "genres": "american shoegaze,dreamo",
    "createdAt": "2022-11-26T02:40:53.276Z",
    "updatedAt": "2022-11-26T02:40:53.276Z"
    },
    {
    "id": 4,
    "name": "brakence",
    "date": "Nov 27, 2022",
    "dayOfWeek": "Sunday",
    "venue": " Lincoln Hall",
    "image": "https://d1ct6yhiw4gple.cloudfront.net/wp-content/uploads/2022/09/11-27-2022-brakence.png",
    "link": "https://lh-st.com/shows/11-27-2022-brakence/",
    "time": "7:30PM",
    "genres": "emo rap,glitchcore,hyperpop,sad rap",
    "createdAt": "2022-11-26T02:40:53.276Z",
    "updatedAt": "2022-11-26T02:40:53.276Z"
    },
    {
    "id": 5,
    "name": "Bluegrass Brunch",
    "date": "November 26, 2022",
    "dayOfWeek": "Saturday",
    "venue": "Beat Kitchen",
    "image": "https://i.ticketweb.com/i/00/10/77/66/49_Listings.jpg?v=7",
    "link": "https://www.beatkitchen.com/event-details/11762165/bluegrass-brunch",
    "time": "11:00 am",
    "genres": "",
    "createdAt": "2022-11-26T02:40:53.276Z",
    "updatedAt": "2022-11-26T02:40:53.276Z"
    },
    {
    "id": 6,
    "name": "The Music of Queen Performed by The Chicago Philharmonic",
    "date": "November 25 2022",
    "dayOfWeek": "Friday",
    "venue": "Riviera Theatre",
    "image": "https://www.jamusa.com/assets/img/MiG_Queen_Live2-med-99808e2a7f.jpg",
    "link": "https://www.jamusa.com/events/detail/the-music-of-queen",
    "time": "Doors: 7:00 PM / Show: 8:00 PM",
    "genres": "album rock",
    "createdAt": "2022-11-26T02:40:53.276Z",
    "updatedAt": "2022-11-26T02:40:53.276Z"
    },
    {
    "id": 7,
    "name": "Like Pacific, My Kid Brother, Bluprint",
    "date": "November 26, 2022",
    "dayOfWeek": "Saturday",
    "venue": "Beat Kitchen",
    "image": "https://i.ticketweb.com/i/00/11/30/71/99_Listings.jpg?v=9",
    "link": "https://www.beatkitchen.com/event-details/12401855/like-pacific-my-kid-brother-bluprint",
    "time": "8:00 pm",
    "genres": "modern alternative rock",
    "createdAt": "2022-11-26T02:40:53.276Z",
    "updatedAt": "2022-11-26T02:40:53.276Z"
    },
    {
    "id": 8,
    "name": "Wallice",
    "date": "Nov 30, 2022",
    "dayOfWeek": "Wednesday",
    "venue": " Schubas",
    "image": "https://d1ct6yhiw4gple.cloudfront.net/wp-content/uploads/2022/10/11-30-2022-wallice.png",
    "link": "https://lh-st.com/shows/11-30-2022-wallice/",
    "time": "8:00PM",
    "genres": "indie pop",
    "createdAt": "2022-11-26T02:40:53.276Z",
    "updatedAt": "2022-11-26T02:40:53.276Z"
    },
    {
    "id": 9,
    "name": "Extraordinary Popular Delusions",
    "date": "November 28, 2022",
    "dayOfWeek": "Monday",
    "venue": "Beat Kitchen",
    "image": "https://i.ticketweb.com/i/00/10/75/91/59_Listings.jpg?v=7",
    "link": "https://www.beatkitchen.com/event-details/11740785/extraordinary-popular-delusions",
    "time": "8:00 pm",
    "genres": "",
    "createdAt": "2022-11-26T02:40:53.276Z",
    "updatedAt": "2022-11-26T02:40:53.276Z"
    },
    {
    "id": 10,
    "name": "Lissie",
    "date": "Dec 01, 2022",
    "dayOfWeek": "Thursday",
    "venue": " Lincoln Hall",
    "image": "https://d1ct6yhiw4gple.cloudfront.net/wp-content/uploads/2022/07/12-01-2022-lissie.png",
    "link": "https://lh-st.com/shows/12-01-2022-lissie/",
    "time": "7:30PM",
    "genres": "folk-pop",
    "createdAt": "2022-11-26T02:40:53.276Z",
    "updatedAt": "2022-11-26T02:40:53.276Z"
    },
    {
    "id": 11,
    "name": "ADVANCE BASE, Spencer Radcliffe, MJ Lenderman",
    "date": "November 28, 2022",
    "dayOfWeek": "Monday",
    "venue": "Beat Kitchen",
    "image": "https://i.ticketweb.com/i/00/11/52/53/69_Listings.jpg?v=4",
    "link": "https://www.beatkitchen.com/event-details/12662155/advance-base-spencer-radcliffe-mj-lenderman",
    "time": "8:00 pm",
    "genres": "lo-fi indie,small room",
    "createdAt": "2022-11-26T02:40:53.276Z",
    "updatedAt": "2022-11-26T02:40:53.276Z"
    },
    {
    "id": 12,
    "name": "beabadoobee",
    "date": "November 29 2022",
    "dayOfWeek": "Tuesday",
    "venue": "Riviera Theatre",
    "image": "https://www.jamusa.com/assets/img/Bea_FallTour_2022_Individual_1-860d32f855.jpg",
    "link": "https://www.jamusa.com/events/detail/beabadoobee-442130",
    "time": "Doors: 6:30 PM / Show: 7:30 PM",
    "genres": "bedroom pop,bubblegrunge,indie pop,pop",
    "createdAt": "2022-11-26T02:40:53.276Z",
    "updatedAt": "2022-11-26T02:40:53.276Z"
    },
    {
    "id": 13,
    "name": "DREAMWELL w/ Crowning, Your Arms Are My Cocoon",
    "date": "November 29, 2022",
    "dayOfWeek": "Tuesday",
    "venue": "Beat Kitchen",
    "image": "https://i.ticketweb.com/i/00/11/40/81/39_Listings.jpg?v=2",
    "link": "https://www.beatkitchen.com/event-details/12530595/dreamwell-w-crowning-your-arms-are-my-cocoon",
    "time": "8:00 pm",
    "genres": "",
    "createdAt": "2022-11-26T02:40:53.276Z",
    "updatedAt": "2022-11-26T02:40:53.276Z"
    },
    {
    "id": 14,
    "name": "Align",
    "date": "Dec 02, 2022",
    "dayOfWeek": "Friday",
    "venue": " Lincoln Hall",
    "image": "https://d1ct6yhiw4gple.cloudfront.net/wp-content/uploads/2022/08/12-02-2022-align.png",
    "link": "https://lh-st.com/shows/12-02-2022-align/",
    "time": "9:00PM",
    "genres": "acid techno,hard techno",
    "createdAt": "2022-11-26T02:40:53.276Z",
    "updatedAt": "2022-11-26T02:40:53.276Z"
    },
    {
    "id": 15,
    "name": "Chicago Underground Comedy",
    "date": "November 29, 2022",
    "dayOfWeek": "Tuesday",
    "venue": "Beat Kitchen",
    "image": "https://i.ticketweb.com/i/00/10/75/35/79_Listings.jpg?v=8",
    "link": "https://www.beatkitchen.com/event-details/11734235/chicago-underground-comedy",
    "time": "9:00 pm",
    "genres": "indie garage rock",
    "createdAt": "2022-11-26T02:40:53.276Z",
    "updatedAt": "2022-11-26T02:40:53.276Z"
    },
    {
    "id": 16,
    "name": "Kainalu",
    "date": "Dec 03, 2022",
    "dayOfWeek": "Saturday",
    "venue": " Schubas",
    "image": "https://d1ct6yhiw4gple.cloudfront.net/wp-content/uploads/2022/10/12-03-2022-kainalu.png",
    "link": "https://lh-st.com/shows/12-03-2022-kainalu/",
    "time": "9:00PM",
    "genres": "neo-psychedelic,wisconsin indie",
    "createdAt": "2022-11-26T02:40:53.276Z",
    "updatedAt": "2022-11-26T02:40:53.276Z"
    },
    {
    "id": 17,
    "name": "Sub-Radio, OLEN",
    "date": "November 30, 2022",
    "dayOfWeek": "Wednesday",
    "venue": "Beat Kitchen",
    "image": "https://i.ticketweb.com/i/00/11/19/16/49_Listings.jpg?v=4",
    "link": "https://www.beatkitchen.com/event-details/12270845/sub-radio-olen",
    "time": "8:00 pm",
    "genres": "dc indie,hopebeat",
    "createdAt": "2022-11-26T02:40:53.276Z",
    "updatedAt": "2022-11-26T02:40:53.276Z"
    },
    {
    "id": 18,
    "name": "Anne-Marie: DYSFUNCTIONAL TOUR",
    "date": "December  1 2022",
    "dayOfWeek": "Thursday",
    "venue": "Park West",
    "image": "https://images.discovery-prod.axs.com/2021/11/uploadedimage_619bfd1bbe639.jpg",
    "link": "https://www.jamusa.com/events/detail/anne-marie-dysfunctional-tour-419873",
    "time": "Doors: 7:00 PM / Show: 8:00 PM",
    "genres": "dance pop,pop,pop dance,post-teen pop,tropical house",
    "createdAt": "2022-11-26T02:40:53.276Z",
    "updatedAt": "2022-11-26T02:40:53.276Z"
    },
    {
    "id": 19,
    "name": "BLACK X-MAS (A Mini Fest) w/ The Ableist, Blackwater Sniper, INPO, Pure Intention, Sawamura No Hitter",
    "date": "December 2, 2022",
    "dayOfWeek": "Friday",
    "venue": "Beat Kitchen",
    "image": "https://i.ticketweb.com/i/00/11/55/74/19_Listings.jpg?v=4",
    "link": "https://www.beatkitchen.com/event-details/12702055/black-x-mas-a-mini-fest-w-the-ableist-blackwater-sniper-inpo-pure-intention-sawamura-no-hitter",
    "time": "7:00 pm",
    "genres": "",
    "createdAt": "2022-11-26T02:40:53.276Z",
    "updatedAt": "2022-11-26T02:40:53.276Z"
    },
    {
    "id": 20,
    "name": "HarbourWingtip",
    "date": "Dec 06, 2022",
    "dayOfWeek": "Tuesday",
    "venue": " Lincoln Hall",
    "image": "https://d1ct6yhiw4gple.cloudfront.net/wp-content/uploads/2022/07/12-06-2022-harbourwingtip.png",
    "link": "https://lh-st.com/shows/12-06-2022-harbour-wingtip/",
    "time": "8:00PM",
    "genres": "cincinnati indie,modern rock",
    "createdAt": "2022-11-26T02:40:53.276Z",
    "updatedAt": "2022-11-26T02:40:53.276Z"
    },
    {
    "id": 21,
    "name": "Bluegrass Brunch",
    "date": "December 3, 2022",
    "dayOfWeek": "Saturday",
    "venue": "Beat Kitchen",
    "image": "https://i.ticketweb.com/i/00/10/77/66/49_Listings.jpg?v=7",
    "link": "https://www.beatkitchen.com/event-details/11762205/bluegrass-brunch",
    "time": "11:00 am",
    "genres": "",
    "createdAt": "2022-11-26T02:40:53.276Z",
    "updatedAt": "2022-11-26T02:40:53.276Z"
    },
    {
    "id": 22,
    "name": "Laura Elliott",
    "date": "Dec 08, 2022",
    "dayOfWeek": "Thursday",
    "venue": " Schubas",
    "image": "https://d1ct6yhiw4gple.cloudfront.net/wp-content/uploads/2022/07/12-08-2022-laura-elliott.png",
    "link": "https://lh-st.com/shows/12-08-2022-laura-elliott/",
    "time": "7:30PM",
    "genres": "indie pop",
    "createdAt": "2022-11-26T02:40:53.276Z",
    "updatedAt": "2022-11-26T02:40:53.276Z"
    },
    {
    "id": 23,
    "name": "IN HER OWN WORDS / CAPSTAN, Cherie Amour, Shallow Pools",
    "date": "December 3, 2022",
    "dayOfWeek": "Saturday",
    "venue": "Beat Kitchen",
    "image": "https://i.ticketweb.com/i/00/11/34/27/89_Listings.jpg?v=2",
    "link": "https://www.beatkitchen.com/event-details/12441405/in-her-own-words-capstan-cherie-amour-shallow-pools",
    "time": "7:30 pm",
    "genres": "",
    "createdAt": "2022-11-26T02:40:53.276Z",
    "updatedAt": "2022-11-26T02:40:53.276Z"
    },
    {
    "id": 24,
    "name": "Ruby Waters",
    "date": "Dec 09, 2022",
    "dayOfWeek": "Friday",
    "venue": " Schubas",
    "image": "https://d1ct6yhiw4gple.cloudfront.net/wp-content/uploads/2022/09/12-09-2022-ruby-waters.png",
    "link": "https://lh-st.com/shows/12-09-2022-ruby-waters/",
    "time": "8:00PM",
    "genres": "",
    "createdAt": "2022-11-26T02:40:53.276Z",
    "updatedAt": "2022-11-26T02:40:53.276Z"
    },
    {
    "id": 25,
    "name": "FRANCISCO MARTIN, Jonny West, Allegra Miles, Fritz Hager",
    "date": "December 4, 2022",
    "dayOfWeek": "Sunday",
    "venue": "Beat Kitchen",
    "image": "https://i.ticketweb.com/i/00/11/47/83/29_Listings.jpg?v=9",
    "link": "https://www.beatkitchen.com/event-details/12601275/francisco-martin-jonny-west-allegra-miles-fritz-hager",
    "time": "9:00 pm",
    "genres": "",
    "createdAt": "2022-11-26T02:40:53.276Z",
    "updatedAt": "2022-11-26T02:40:53.276Z"
    },
    {
    "id": 26,
    "name": "Mr. Dave's 6th Annual Holiday Jamboree",
    "date": "Dec 10, 2022",
    "dayOfWeek": "Saturday",
    "venue": " Lincoln Hall",
    "image": "https://d1ct6yhiw4gple.cloudfront.net/wp-content/uploads/2019/10/MrDaveSITE.jpg",
    "link": "https://lh-st.com/shows/12-10-2022-mr-daves-holiday-jamboree/",
    "time": "11:00AM",
    "genres": "",
    "createdAt": "2022-11-26T02:40:53.276Z",
    "updatedAt": "2022-11-26T02:40:53.276Z"
    },
    {
    "id": 27,
    "name": "Extraordinary Popular Delusions",
    "date": "December 5, 2022",
    "dayOfWeek": "Monday",
    "venue": "Beat Kitchen",
    "image": "https://i.ticketweb.com/i/00/10/75/91/59_Listings.jpg?v=7",
    "link": "https://www.beatkitchen.com/event-details/11740805/extraordinary-popular-delusions",
    "time": "8:00 pm",
    "genres": "",
    "createdAt": "2022-11-26T02:40:53.276Z",
    "updatedAt": "2022-11-26T02:40:53.276Z"
    },
    {
    "id": 28,
    "name": "Azizi Gibson",
    "date": "Dec 10, 2022",
    "dayOfWeek": "Saturday",
    "venue": " Schubas",
    "image": "https://d1ct6yhiw4gple.cloudfront.net/wp-content/uploads/2022/09/12-10-2022-azizi-gibson.png",
    "link": "https://lh-st.com/shows/12-10-2022-azizi-gibson/",
    "time": "9:00PM",
    "genres": "dark trap,underground hip hop",
    "createdAt": "2022-11-26T02:40:53.276Z",
    "updatedAt": "2022-11-26T02:40:53.276Z"
    },
    {
    "id": 29,
    "name": "THE SKIES ARE SHIFTING, When We Was Kids, Luzma, Sawbuck",
    "date": "December 5, 2022",
    "dayOfWeek": "Monday",
    "venue": "Beat Kitchen",
    "image": "https://i.ticketweb.com/i/00/11/55/25/49_Listings.jpg?v=2",
    "link": "https://www.beatkitchen.com/event-details/12697555/the-skies-are-shifting-when-we-was-kids-luzma-sawbuck",
    "time": "8:00 pm",
    "genres": "",
    "createdAt": "2022-11-26T02:40:53.276Z",
    "updatedAt": "2022-11-26T02:40:53.276Z"
    },
    {
    "id": 30,
    "name": "Phony Ppl",
    "date": "Dec 11, 2022",
    "dayOfWeek": "Sunday",
    "venue": " Lincoln Hall",
    "image": "https://d1ct6yhiw4gple.cloudfront.net/wp-content/uploads/2022/09/12-11-2022-phony-ppl.png",
    "link": "https://lh-st.com/shows/12-11-2022-phony-ppl/",
    "time": "8:00PM",
    "genres": "alternative r&b,indie r&b,indie soul",
    "createdAt": "2022-11-26T02:40:53.276Z",
    "updatedAt": "2022-11-26T02:40:53.276Z"
    },
    {
    "id": 31,
    "name": "Chicago Underground Comedy",
    "date": "December 6, 2022",
    "dayOfWeek": "Tuesday",
    "venue": "Beat Kitchen",
    "image": "https://i.ticketweb.com/i/00/10/75/35/79_Listings.jpg?v=8",
    "link": "https://www.beatkitchen.com/event-details/11734255/chicago-underground-comedy",
    "time": "9:00 pm",
    "genres": "indie garage rock",
    "createdAt": "2022-11-26T02:40:53.276Z",
    "updatedAt": "2022-11-26T02:40:53.276Z"
    },
    {
    "id": 32,
    "name": "AQUIHAYAQUIHAY",
    "date": "Dec 13, 2022",
    "dayOfWeek": "Tuesday",
    "venue": " Schubas",
    "image": "https://d1ct6yhiw4gple.cloudfront.net/wp-content/uploads/2022/11/12-13-2022-AQUIHAYAQUIHAY.png",
    "link": "https://lh-st.com/shows/12-13-2022-aquihayaquihay/",
    "time": "8:00PM",
    "genres": "mexican hip hop,r&b en espanol,rap regio",
    "createdAt": "2022-11-26T02:40:53.276Z",
    "updatedAt": "2022-11-26T02:40:53.276Z"
    },
    {
    "id": 33,
    "name": "Splice Series",
    "date": "December 7, 2022",
    "dayOfWeek": "Wednesday",
    "venue": "Beat Kitchen",
    "image": "https://i.ticketweb.com/i/00/11/47/46/69_Listings.jpg?v=3",
    "link": "https://www.beatkitchen.com/event-details/12597585/splice-series",
    "time": "8:30 pm",
    "genres": "dreamo,indie punk",
    "createdAt": "2022-11-26T02:40:53.276Z",
    "updatedAt": "2022-11-26T02:40:53.276Z"
    },
    {
    "id": 34,
    "name": "Larrenwong",
    "date": "Dec 15, 2022",
    "dayOfWeek": "Thursday",
    "venue": " Schubas",
    "image": "https://d1ct6yhiw4gple.cloudfront.net/wp-content/uploads/2022/10/12-15-2022-larrenwong-1.png",
    "link": "https://lh-st.com/shows/12-15-2022-larrenwong/",
    "time": "9:00PM",
    "genres": "indie r&b",
    "createdAt": "2022-11-26T02:40:53.276Z",
    "updatedAt": "2022-11-26T02:40:53.276Z"
    },
    {
    "id": 35,
    "name": "SWEARWORDS, Baby Money & The Down Payments, 3-Point Sinker",
    "date": "December 8, 2022",
    "dayOfWeek": "Thursday",
    "venue": "Beat Kitchen",
    "image": "https://i.ticketweb.com/i/00/11/48/13/09_Listings.jpg?v=2",
    "link": "https://www.beatkitchen.com/event-details/12604205/swearwords-baby-money-the-down-payments-3-point-sinker",
    "time": "8:00 pm",
    "genres": "",
    "createdAt": "2022-11-26T02:40:53.276Z",
    "updatedAt": "2022-11-26T02:40:53.276Z"
    },
    {
    "id": 36,
    "name": "Soft and Dumb",
    "date": "Dec 17, 2022",
    "dayOfWeek": "Saturday",
    "venue": " Schubas",
    "image": "https://d1ct6yhiw4gple.cloudfront.net/wp-content/uploads/2022/11/12-17-2022-soft-and-dumb.png",
    "link": "https://lh-st.com/shows/12-17-2022-soft-and-dumb/",
    "time": "8:00PM",
    "genres": "",
    "createdAt": "2022-11-26T02:40:53.276Z",
    "updatedAt": "2022-11-26T02:40:53.276Z"
    },
    {
    "id": 37,
    "name": "YOUR NEIGHBORS",
    "date": "December 9, 2022",
    "dayOfWeek": "Friday",
    "venue": "Beat Kitchen",
    "image": "https://i.ticketweb.com/i/00/11/51/74/29_Listings.jpg?v=2",
    "link": "https://www.beatkitchen.com/event-details/12653255/your-neighbors",
    "time": "8:00 pm",
    "genres": "nashville indie",
    "createdAt": "2022-11-26T02:40:53.276Z",
    "updatedAt": "2022-11-26T02:40:53.276Z"
    },
    {
    "id": 38,
    "name": "Karaoke Storytellers",
    "date": "Dec 18, 2022",
    "dayOfWeek": "Sunday",
    "venue": " Schubas",
    "image": "https://d1ct6yhiw4gple.cloudfront.net/wp-content/uploads/2021/06/08-15-21-Karaoke-Storytellers.jpg",
    "link": "https://lh-st.com/shows/12-18-2022-karaoke-storytellers/",
    "time": "7:00PM",
    "genres": "karaoke",
    "createdAt": "2022-11-26T02:40:53.276Z",
    "updatedAt": "2022-11-26T02:40:53.276Z"
    },
    {
    "id": 39,
    "name": "Bluegrass Brunch",
    "date": "December 10, 2022",
    "dayOfWeek": "Saturday",
    "venue": "Beat Kitchen",
    "image": "https://i.ticketweb.com/i/00/10/77/66/49_Listings.jpg?v=7",
    "link": "https://www.beatkitchen.com/event-details/11762185/bluegrass-brunch",
    "time": "11:00 am",
    "genres": "",
    "createdAt": "2022-11-26T02:40:53.276Z",
    "updatedAt": "2022-11-26T02:40:53.276Z"
    },
    {
    "id": 40,
    "name": "Schubas Open Mic",
    "date": "Dec 22, 2022",
    "dayOfWeek": "Thursday",
    "venue": " Schubas",
    "image": "https://d1ct6yhiw4gple.cloudfront.net/wp-content/uploads/2022/09/OPEN-MIC-web-pic.png",
    "link": "https://lh-st.com/shows/12-22-2022-schubas-open-mic/",
    "time": "8:00PM",
    "genres": "japanese r&b",
    "createdAt": "2022-11-26T02:40:53.276Z",
    "updatedAt": "2022-11-26T02:40:53.276Z"
    },
    {
    "id": 41,
    "name": "emlyn - no one came to grieve tour",
    "date": "December 10, 2022",
    "dayOfWeek": "Saturday",
    "venue": "Beat Kitchen",
    "image": "https://i.ticketweb.com/i/00/11/37/66/89_Listings.jpg?v=11",
    "link": "https://www.beatkitchen.com/event-details/12488595/emlyn-no-one-came-to-grieve-tour",
    "time": "7:00 pm",
    "genres": "alt z,social media pop",
    "createdAt": "2022-11-26T02:40:53.276Z",
    "updatedAt": "2022-11-26T02:40:53.276Z"
    },
    {
    "id": 42,
    "name": "KennyHoopla Presents: Home for the Holidays",
    "date": "Dec 29, 2022",
    "dayOfWeek": "Thursday",
    "venue": " Lincoln Hall",
    "image": "https://d1ct6yhiw4gple.cloudfront.net/wp-content/uploads/2022/11/12-29-2022-kennyhoopla.png",
    "link": "https://lh-st.com/shows/12-29-2022-kennyhoopla/",
    "time": "7:30PM",
    "genres": "pop punk",
    "createdAt": "2022-11-26T02:40:53.276Z",
    "updatedAt": "2022-11-26T02:40:53.276Z"
    },
    {
    "id": 43,
    "name": "Chicago Underground Comedy",
    "date": "December 20, 2022",
    "dayOfWeek": "Tuesday",
    "venue": "Beat Kitchen",
    "image": "https://i.ticketweb.com/i/00/10/75/35/79_Listings.jpg?v=8",
    "link": "https://www.beatkitchen.com/event-details/11734275/chicago-underground-comedy",
    "time": "9:00 pm",
    "genres": "indie garage rock",
    "createdAt": "2022-11-26T02:40:53.276Z",
    "updatedAt": "2022-11-26T02:40:53.276Z"
    },
    {
    "id": 44,
    "name": "Schubas Open Mic",
    "date": "Dec 29, 2022",
    "dayOfWeek": "Thursday",
    "venue": " Schubas",
    "image": "https://d1ct6yhiw4gple.cloudfront.net/wp-content/uploads/2022/09/OPEN-MIC-web-pic.png",
    "link": "https://lh-st.com/shows/12-29-2022-schubas-open-mic/",
    "time": "8:00PM",
    "genres": "japanese r&b",
    "createdAt": "2022-11-26T02:40:53.276Z",
    "updatedAt": "2022-11-26T02:40:53.276Z"
    },
    {
    "id": 45,
    "name": "Splice Series",
    "date": "December 21, 2022",
    "dayOfWeek": "Wednesday",
    "venue": "Beat Kitchen",
    "image": "https://i.ticketweb.com/i/00/11/47/46/69_Listings.jpg?v=3",
    "link": "https://www.beatkitchen.com/event-details/12597535/splice-series",
    "time": "8:30 pm",
    "genres": "dreamo,indie punk",
    "createdAt": "2022-11-26T02:40:53.276Z",
    "updatedAt": "2022-11-26T02:40:53.276Z"
    },
    {
    "id": 46,
    "name": "Fortune Dance Party",
    "date": "Dec 30, 2022",
    "dayOfWeek": "Friday",
    "venue": " Lincoln Hall",
    "image": "https://d1ct6yhiw4gple.cloudfront.net/wp-content/uploads/2022/11/12-30-2022-fortune-dance-party.png",
    "link": "https://lh-st.com/shows/12-30-2022-fortune-dance-party/",
    "time": "9:00PM",
    "genres": "french synthpop",
    "createdAt": "2022-11-26T02:40:53.276Z",
    "updatedAt": "2022-11-26T02:40:53.276Z"
    },
    {
    "id": 47,
    "name": "THE CURE / SMITHS TRIBUTE",
    "date": "December 23, 2022",
    "dayOfWeek": "Friday",
    "venue": "Beat Kitchen",
    "image": "https://i.ticketweb.com/i/00/11/53/26/69_Listings.jpg?v=3",
    "link": "https://www.beatkitchen.com/event-details/12676895/the-cure-smiths-tribute",
    "time": "8:00 pm",
    "genres": "",
    "createdAt": "2022-11-26T02:40:53.276Z",
    "updatedAt": "2022-11-26T02:40:53.276Z"
    },
    {
    "id": 48,
    "name": "The Dead Licks",
    "date": "Dec 31, 2022",
    "dayOfWeek": "Saturday",
    "venue": " Schubas",
    "image": "https://d1ct6yhiw4gple.cloudfront.net/wp-content/uploads/2022/10/12-31-2022-the-dead-licks.png",
    "link": "https://lh-st.com/shows/12-31-2022-the-dead-licks/",
    "time": "9:00PM",
    "genres": "",
    "createdAt": "2022-11-26T02:40:53.276Z",
    "updatedAt": "2022-11-26T02:40:53.276Z"
    },
    {
    "id": 49,
    "name": "Bluegrass Brunch",
    "date": "December 24, 2022",
    "dayOfWeek": "Saturday",
    "venue": "Beat Kitchen",
    "image": "https://i.ticketweb.com/i/00/10/77/66/49_Listings.jpg?v=7",
    "link": "https://www.beatkitchen.com/event-details/11762235/bluegrass-brunch",
    "time": "11:00 am",
    "genres": "",
    "createdAt": "2022-11-26T02:40:53.276Z",
    "updatedAt": "2022-11-26T02:40:53.276Z"
    },
    {
    "id": 50,
    "name": "feeble little horse",
    "date": "Jan 03, 2023",
    "dayOfWeek": "Tuesday",
    "venue": " Schubas",
    "image": "https://d1ct6yhiw4gple.cloudfront.net/wp-content/uploads/2022/10/01-03-2023-feeble-little-horse.png",
    "link": "https://lh-st.com/shows/01-03-2023-feeble-little-horse/",
    "time": "8:00PM",
    "genres": "lo-fi indie",
    "createdAt": "2022-11-26T02:40:53.276Z",
    "updatedAt": "2022-11-26T02:40:53.276Z"
    },
    {
    "id": 51,
    "name": "Extraordinary Popular Delusions",
    "date": "December 26, 2022",
    "dayOfWeek": "Monday",
    "venue": "Beat Kitchen",
    "image": "https://i.ticketweb.com/i/00/10/75/91/59_Listings.jpg?v=7",
    "link": "https://www.beatkitchen.com/event-details/11740825/extraordinary-popular-delusions",
    "time": "8:00 pm",
    "genres": "",
    "createdAt": "2022-11-26T02:40:53.276Z",
    "updatedAt": "2022-11-26T02:40:53.276Z"
    },
    {
    "id": 52,
    "name": "Brendan Bayliss and Jake Cinninger",
    "date": "December 10 2022",
    "dayOfWeek": "Saturday",
    "venue": "Park West",
    "image": "https://www.jamusa.com/assets/img/Umphreys_AnnualHoliday2022_1200x627-9ee52712bc.jpg",
    "link": "https://www.jamusa.com/events/detail/brendan-bayliss-and-jake-cinninger-456553",
    "time": "Doors: 7:00 PM / Show: 8:00 PM",
    "genres": "",
    "createdAt": "2022-11-26T02:40:53.276Z",
    "updatedAt": "2022-11-26T02:40:53.276Z"
    },
    {
    "id": 53,
    "name": "Chicago Underground Comedy",
    "date": "December 27, 2022",
    "dayOfWeek": "Tuesday",
    "venue": "Beat Kitchen",
    "image": "https://i.ticketweb.com/i/00/11/54/52/19_Listings.jpg?v=1",
    "link": "https://www.beatkitchen.com/event-details/11734285/chicago-underground-comedy",
    "time": "9:00 pm",
    "genres": "indie garage rock",
    "createdAt": "2022-11-26T02:40:53.276Z",
    "updatedAt": "2022-11-26T02:40:53.276Z"
    },
    {
    "id": 54,
    "name": "Tomberlin",
    "date": "Jan 18, 2023",
    "dayOfWeek": "Wednesday",
    "venue": " Schubas",
    "image": "https://d1ct6yhiw4gple.cloudfront.net/wp-content/uploads/2022/11/01-18-2023-tomberlin.png",
    "link": "https://lh-st.com/shows/01-18-2023-tomberlin-tnk/",
    "time": "7:00PM",
    "genres": "art pop,bubblegrunge,indie folk,indie pop,indie rock,louisville indie,small room",
    "createdAt": "2022-11-26T02:40:53.276Z",
    "updatedAt": "2022-11-26T02:40:53.276Z"
    },
    {
    "id": 55,
    "name": "The Nubile Thangs w/ Outronaut",
    "date": "December 29, 2022",
    "dayOfWeek": "Thursday",
    "venue": "Beat Kitchen",
    "image": "https://i.ticketweb.com/i/00/11/55/61/19_Listings.jpg?v=2",
    "link": "https://www.beatkitchen.com/event-details/12700655/the-nubile-thangs-w-outronaut",
    "time": "8:00 pm",
    "genres": "",
    "createdAt": "2022-11-26T02:40:53.276Z",
    "updatedAt": "2022-11-26T02:40:53.276Z"
    },
    {
    "id": 56,
    "name": "Cloakroom",
    "date": "Jan 19, 2023",
    "dayOfWeek": "Thursday",
    "venue": " Schubas",
    "image": "https://d1ct6yhiw4gple.cloudfront.net/wp-content/uploads/2022/11/01-19-2023-cloakroom.png",
    "link": "https://lh-st.com/shows/01-19-2023-cloakroom-tnk/",
    "time": "8:00PM",
    "genres": "american shoegaze,dreamo,grungegaze,indie punk,nu gaze,spacegrunge",
    "createdAt": "2022-11-26T02:40:53.276Z",
    "updatedAt": "2022-11-26T02:40:53.276Z"
    },
    {
    "id": 57,
    "name": "Bluegrass Brunch",
    "date": "December 31, 2022",
    "dayOfWeek": "Saturday",
    "venue": "Beat Kitchen",
    "image": "https://i.ticketweb.com/i/00/10/77/66/49_Listings.jpg?v=7",
    "link": "https://www.beatkitchen.com/event-details/11762245/bluegrass-brunch",
    "time": "11:00 am",
    "genres": "",
    "createdAt": "2022-11-26T02:40:53.276Z",
    "updatedAt": "2022-11-26T02:40:53.276Z"
    },
    {
    "id": 58,
    "name": "Armand Hammer",
    "date": "Jan 20, 2023",
    "dayOfWeek": "Friday",
    "venue": " Schubas",
    "image": "https://d1ct6yhiw4gple.cloudfront.net/wp-content/uploads/2022/11/01-20-2023-armand-hammer.png",
    "link": "https://lh-st.com/shows/01-20-2023-armand-hammer-tnk/",
    "time": "9:00PM",
    "genres": "alternative hip hop,boom bap,experimental hip hop",
    "createdAt": "2022-11-26T02:40:53.276Z",
    "updatedAt": "2022-11-26T02:40:53.276Z"
    },
    {
    "id": 59,
    "name": "CANCELLED - The Coronas",
    "date": "December 31, 2022",
    "dayOfWeek": "Saturday",
    "venue": "Beat Kitchen",
    "image": "https://i.ticketweb.com/i/00/09/58/24/49_Listings.jpg?v=9",
    "link": "https://www.beatkitchen.com/event-details/10331185/cancelled-the-coronas",
    "time": "8:00 pm",
    "genres": "irish rock",
    "createdAt": "2022-11-26T02:40:53.276Z",
    "updatedAt": "2022-11-26T02:40:53.276Z"
    },
    {
    "id": 60,
    "name": "MAVI",
    "date": "Jan 22, 2023",
    "dayOfWeek": "Sunday",
    "venue": " Lincoln Hall",
    "image": "https://d1ct6yhiw4gple.cloudfront.net/wp-content/uploads/2022/11/01-22-2023-mavi.png",
    "link": "https://lh-st.com/shows/01-22-2023-mavi-tnk/",
    "time": "6:30PM",
    "genres": "hip hop,rap,underground hip hop",
    "createdAt": "2022-11-26T02:40:53.276Z",
    "updatedAt": "2022-11-26T02:40:53.276Z"
    },
    {
    "id": 61,
    "name": "Dos Santos",
    "date": "December 31, 2022",
    "dayOfWeek": "Saturday",
    "venue": "Beat Kitchen",
    "image": "https://i.ticketweb.com/i/00/11/49/61/39_Listings.jpg?v=3",
    "link": "https://www.beatkitchen.com/event-details/12621555/dos-santos",
    "time": "10:00 pm",
    "genres": "latin afrobeat",
    "createdAt": "2022-11-26T02:40:53.276Z",
    "updatedAt": "2022-11-26T02:40:53.276Z"
    },
    {
    "id": 62,
    "name": "Quinn Christopherson",
    "date": "Jan 26, 2023",
    "dayOfWeek": "Thursday",
    "venue": " Schubas",
    "image": "https://d1ct6yhiw4gple.cloudfront.net/wp-content/uploads/2022/10/01-26-2023-quinn-christopherson.png",
    "link": "https://lh-st.com/shows/01-26-2023-quinn-christopherson/",
    "time": "8:00PM",
    "genres": "",
    "createdAt": "2022-11-26T02:40:53.276Z",
    "updatedAt": "2022-11-26T02:40:53.276Z"
    },
    {
    "id": 63,
    "name": "Extraordinary Popular Delusions",
    "date": "January 16, 2023",
    "dayOfWeek": "Monday",
    "venue": "Beat Kitchen",
    "image": "https://i.ticketweb.com/i/00/10/75/91/59_Listings.jpg?v=7",
    "link": "https://www.beatkitchen.com/event-details/11740875/extraordinary-popular-delusions",
    "time": "8:00 pm",
    "genres": "",
    "createdAt": "2022-11-26T02:40:53.276Z",
    "updatedAt": "2022-11-26T02:40:53.276Z"
    },
    {
    "id": 64,
    "name": "MorMor",
    "date": "Jan 28, 2023",
    "dayOfWeek": "Saturday",
    "venue": " Lincoln Hall",
    "image": "https://d1ct6yhiw4gple.cloudfront.net/wp-content/uploads/2022/09/01-28-2023-mormor.png",
    "link": "https://lh-st.com/shows/01-28-2023-mormor/",
    "time": "9:00PM",
    "genres": "canadian contemporary r&b,indie soul,vapor soul",
    "createdAt": "2022-11-26T02:40:53.276Z",
    "updatedAt": "2022-11-26T02:40:53.276Z"
    }
]